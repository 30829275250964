import classes from "./App.module.css";

const App = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.main}>
        <div className={classes.intro}>
          Fantasy user interfaces for content creators. <br />
          Coming soon.
        </div>
      </div>
      <div className={classes.footer}>livetheme.io</div>
    </div>
  );
};

export default App;

console.log("You are curious. I like it :)");
